module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-impo_55mchqq4eoeml4oqpkqltfghpy/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-143764638-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"cookie_name":"vallista.kr","sample_rate":5},"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"origin":"vallista.kr","exclude":[]}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-remark-images@6.25.0_gatsby-plugin-sharp@4.25.1_gatsby@5.14.1_babel-eslint@10.1.0_esli_zldez2vpl7ekop3wcnnengoskq/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-nprogress@4.25.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-import_ijplkzjm2xdaxny5hyn7f6aooq/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff0080","showSpinner":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-import-_pbhcibie7vz47aguz2yave74vq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vallista의 블로그","short_name":"Vallista","start_url":"/","icon":"static/favicons/favicon-96x96.png","icons":[{"src":"/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ad4b2d0e2a2cbdad6966358a512cff94"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-import-resolver-typescript@3.7.0_esli_ny7q453f5qrh5vpzh62eeqmpju/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
